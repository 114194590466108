import { Grid, Typography } from '@material-ui/core';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import MarkdownContent from '../mol.markdown-content/markdown-content.component';
import CotationForm from '../org.cotation-form/cotation-form.container';
import Seo from '../seo';
import { GatsbyImageRoundBorderStyled } from '../atm.gatsby-image-round-border/gatsby-image-round-border.styled';

export interface IContentPageLayoutProps {
  html: any;
  title: string;
  gatsbyImageData: any;
}

export const ContentPageLayout: React.FunctionComponent<
  IContentPageLayoutProps
> = (props) => {
  return (
    <>
      <Seo title={props.title} />
      <Grid container spacing={4} justify="space-evenly">
        <Grid item container xs={12} />
        <Grid container item xs={10} md={6} alignItems="center">
          <Typography variant="h1">{props.title}</Typography>
        </Grid>
        <Grid item container xs={10} md={4} lg={3} />
        <Grid
          container
          item
          xs={10}
          md={6}
          alignItems="center"
          justify="center"
        >
          <Grid item>
            <GatsbyImageRoundBorderStyled
              image={props.gatsbyImageData}
              alt={`${props.title} hero image`}
            />
          </Grid>
          <Grid item xs={12}>
            <MarkdownContent innerHTML={props.html} />
          </Grid>
        </Grid>
        <Grid item container xs={10} md={4} lg={3}>
          <CotationForm />
        </Grid>
        <Grid item container xs={12} />
      </Grid>
    </>
  );
};
