import { Paper } from '@material-ui/core';
import { PageProps } from 'gatsby';
import React from 'react';
import { ContentPageLayout } from '../components/org.content-page-layout/content-page-layout.component';
import PageLayout from '../components/org.page-layout/page-layout.component';

const CompanyPage = (props: PageProps) => {
  const data = (props.pageContext as any).markDownRemark;
  const metadata = data.frontmatter;
  return (
    <>
      <ContentPageLayout
        title={metadata.title}
        gatsbyImageData={
          metadata.companyHeroImage.childImageSharp.gatsbyImageData
        }
        html={data.html}
      />
    </>
  );
};

export default CompanyPage;
