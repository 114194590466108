import * as React from 'react';
import { TypographyMarkdownWrapper } from './markdown-content.styled';

interface IMarkdownContentProps {
  innerHTML: any;
}

const MarkdownContent: React.FunctionComponent<IMarkdownContentProps> = (
  props
) => {
  return (
    <TypographyMarkdownWrapper>
      <div dangerouslySetInnerHTML={{ __html: props.innerHTML }} />
    </TypographyMarkdownWrapper>
  );
};

export default MarkdownContent;
